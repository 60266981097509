export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"hid":"description","name":"description","content":"Eduworky est une plateforme complète pour la gestion scolaire. Suivez les notes, les absences et simplifiez la gestion administrative de votre établissement scolaire."},{"hid":"keywords","name":"keywords","content":"gestion scolaire, scolarité, suivi des notes, absences, gestion des absences, éducation, plateforme scolaire, gestion administrative, notes des élèves, établissement scolaire"},{"hid":"author","name":"author","content":"Eduworky"},{"hid":"og:title","property":"og:title","content":"Eduworky - Plateforme de Gestion Scolaire Complète"},{"hid":"og:description","property":"og:description","content":"Simplifiez la gestion de vos écoles et associations avec Eduworky. Suivi des notes, gestion des absences, et plus encore."},{"hid":"og:type","property":"og:type","content":"website"},{"hid":"og:url","property":"og:url","content":"https://www.eduworky.com"},{"hid":"og:image","property":"og:image","content":"https://www.eduworky.com/favicon.ico"},{"hid":"twitter:title","name":"twitter:title","content":"Eduworky - Plateforme de Gestion Scolaire"},{"hid":"twitter:description","name":"twitter:description","content":"Accédez à une gestion complète de la scolarité avec Eduworky. Suivi des notes, gestion des absences, et bien plus encore."},{"hid":"twitter:card","name":"twitter:card","content":"summary_large_image"},{"hid":"twitter:image","name":"twitter:image","content":"https://www.eduworky.com/favicon.ico"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"canonical","href":"https://www.eduworky.com"}],"style":[],"script":[],"noscript":[],"title":"Gestion Scolaire - Suivi des Notes, Absences, et Plus"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"