<template>

  <NuxtLoadingIndicator :height="5" :color="$vuetify.theme.themes.light.colors.secondary"/>

  <NuxtLayout>
    <v-app>
      <NuxtPage/>
      <Footer/>
    </v-app>
  </NuxtLayout>

</template>

<script setup>
import {defineAsyncComponent} from "vue";

const Footer = defineAsyncComponent(() => import('@/components/Footer.vue'));

/**
 * Meta data
 */
useHead({
  title: "Eduworky - La plateforme pour gérer les associations et les écoles",
  htmlAttrs: {
    lang: "fr"
  },
  meta: [
    {name: 'theme-color', content: '#005BFF'},
    {
      name: 'description',
      content: "Eduworky est la plateforme idéale pour gérer efficacement les associations et les écoles. Simplifiez la gestion administrative et améliorez l'engagement des membres."
    },
    {name: 'keywords', content: "gestion, associations, écoles, plateforme, Eduworky"},
    {name: 'author', content: "Équipe Eduworky"},
    {name: 'copyright', content: "Eduworky © 2024"},
    {name: 'robots', content: 'index, follow'},
    {name: 'canonical', content: '/'},
    {name: 'sitemap', content: '/sitemap.xml'}
  ],
  link: [
    {rel: 'canonical', href: '/'},
    {rel: 'sitemap', href: '/sitemap.xml'},
    {rel: 'icon', type: 'image/png', href: '/favicon.ico'},
    {rel: 'apple-touch-icon', type: 'image/png', href: '/favicon.ico'}
  ]
})
</script>
