export default defineNuxtPlugin(() => {

    const originalConsoleError = console.error;
    const originalConsoleWarn = console.warn;

    console.error = (...args) => {
        if (args[0] && typeof args[0] === 'string' && args[0].includes('Hydration completed but contains mismatches')) {
            return;
        }
        originalConsoleError.apply(console, args);
    };

    console.warn = (...args) => {
        originalConsoleWarn.apply(console, args);
    };
});
