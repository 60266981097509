import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

export default defineNuxtPlugin(async (nuxtApp) => {
    const vuetify = createVuetify({
        components: {
            ...components,
        },
        directives,

        theme: {
            themes: {
                light: {
                    dark: false,
                    colors: {
                        primary: "#005BFF",
                        secondary: "#20D489",
                    }
                },
            },
        },
    })

    nuxtApp.vueApp.use(vuetify)
    nuxtApp.provide('vuetify', vuetify)
})
